import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify/';
import Router, { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import getConfig from 'next/config';
import qs from 'querystring';
import cookies from 'js-cookie';
import { Carousel, CarouselSlide, SEOAccordion } from '@lifepal/lifepal-design-system';

import { NextServerAPI, tomiAI } from '../client/utils';
import HeroLanding from '../components/pages/Landing/HeroLanding/HeroLanding';
import { Avatar } from '../components/Avatar/Avatar';
import GoogleReview from '../components/pages/Landing/GoogleReview';
import LandingLeadForm from '../components/pages/Landing/LandingLeadForm/LandingLeadForm';
import StickyWhatsapp from '../components/pages/Landing/StickyWhatsapp';
import { sendTracker } from '../lib/tracker';
import { cleanPhoneNumber } from '../lib/phoneNumber';
import { useAuth } from '../providers/Auth';
import { KYCVerificationModal } from '../client/modules/account/components/KYCVerification/KYCVerificationModal';

import 'pure-react-carousel/dist/react-carousel.es.css';
import '../styles/components/iconSprites.scss';
import '../styles/Landing.module.scss';

const Meta = dynamic(() => import('../components/Meta'));
const BlogPost = dynamic(() => import('../components/pages/Landing/BlogPost'));

import { useMediaQuery } from '../client/utils/useMediaQuery';
import { BREAK_POINTS } from '../client/utils/breakpoint';
import { addRecepient, addSendGridList, SEND_GRID_ID } from '../client/utils/sendgridRecepient';
import { useIntersectionObserver } from '../client/utils/useIntersectionObserver';
import { MEDIA_RECOMMENDED_ARTICLES } from '../components/pages/Landing/constant';
import { AGENT_CONSTANTS } from '../client/modules/agents/constants/agent.constants';

const { APP_ENV } = getConfig().publicRuntimeConfig;

const detectWebPSupport = () => new Promise((res) => {
  const webP = new Image();
  webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
  webP.onload = webP.onerror = () => {
    res(webP.height === 2);
  };
});

const LandingPage = ({ query, dispatch }) => {

  const isDesktop = useMediaQuery(BREAK_POINTS.desktop);
  const isTablet = useMediaQuery(BREAK_POINTS.tablet);
  const { authToken } = useAuth();
  const router_ = useRouter();

  const [hasWEBPSupport, setWEBPSupport] = React.useState('');
  const [shouldShowKYCVerification, setShowKYCVerification] = React.useState(false);
  const [personId, setPersonId] = React.useState('');
  const [userProfile, setUserProfile] = React.useState('');

  const mediaSliderOptions = {
    root: process.browser ? document.querySelector('.media__sprite--image') : null,
    rootMargin: "0px",
    threshold: 1.0
  };

  const [mediaSlidercontainerRef, isMediaSliderVisible] = useIntersectionObserver(mediaSliderOptions);

  useEffect(() => {
    if (isMediaSliderVisible) {
      let slideList = document.querySelectorAll('.media__sprite--image');
      Array.from(slideList).map(slideItem => {
        slideItem.style.backgroundImage =
          `url(${hasWEBPSupport ? '/static/images/media/media.webp' : '/static/images/media/media.png'})`;
      });
    }
  }, [isMediaSliderVisible]);

  useEffect(() => {
    cookies.remove('phoneNumber');
    detectWebPSupport().then((hasWebPSupport) => {
      if (hasWebPSupport) {
        document.documentElement.classList.remove('no-webp');
        setWEBPSupport(true);
      } else {
        document.documentElement.classList.remove('has-webp');
      }
    });
  }, []);

  useEffect(() => {
    if (authToken) {
      const agent = AGENT_CONSTANTS.getAgentByToken(authToken);
      if (agent.isExternalAgent || agent.isInternalAgent || agent.isTSOSupervisor) {
        return;
      }

      dispatch({
        type: 'GET_USER_PROFILE',
        payload: { token: authToken },
        callback: (profiles) => {
          if (profiles && profiles.length) {
            const mainProfile = profiles.find(row => row.mainProfile);
            if (mainProfile) {
              const identityNumber =
                mainProfile.passportNumber
                || mainProfile.sourceOfFund
                || mainProfile.monthlyIncome
                || mainProfile.identityCardNumber
                || '';
              setShowKYCVerification(!identityNumber);
              setUserProfile(mainProfile);
              setPersonId(mainProfile.id);
            }
          }
        }
      });
    }
  }, [authToken]);

  const getMediaSlides = (items) => {
    return items.map((item) => (
      <CarouselSlide key={item.id}>
        <div
          className="product__slides-wrapper"
          style={{
            flexDirection: 'column',
            marginTop: '12px'
          }}
        >
          {
            (hasWEBPSupport !== '') && (
              <div
                className="media__sprite--image"
                id='media-sprite'
                style={{
                  backgroundImage: `url(${hasWEBPSupport ? item.spriteURL : item.spriteFallbackURL})`,
                  backgroundPosition: item.background,
                  backgroundSize: 'cover',
                  display: 'inline-flex',
                  height: '40px',
                  width: '100px',
                  filter: 'grayscale(100%)',
                  transform: 'scale(1.25)',
                }}
              />
            )
          }
        </div>
      </CarouselSlide>
    ));
  };

  const getPromosSlides = (items) => {
    return items.map((item) => (
      <CarouselSlide key={item.id}>
        <a href={item.redirectURL} rel="noopener noreferrer" style={{ flexDirection: 'column' }}>
          <picture>
            <source srcSet={item.imgURL} type="image/webp" />
            <source srcSet={item.imgURL} type="image/svg" />
            <Avatar
              className="discount__item"
              src={item.fallbackImgURL}
              alt={item.alt}
              loading="lazy"
              height="100%"
              width="100%"
            />
          </picture>
        </a>
      </CarouselSlide>
    ));
  };

  const getAboutSlides = (items) => {
    return items.map((item) => (
      <CarouselSlide key={item.id}>
        <p className="review heading-sm-r">
          {item.review}
        </p>
        <p className="heading-md-m reviewer__name">{item.name}</p>
      </CarouselSlide>
    ));
  };

  const getInsuraceSlides = (items) => {
    return items.map((item) => (
      <CarouselSlide key={item.id}>
        <a
          className="product__slides-wrapper"
          href={item.url ? item.url : '#'}
          rel="noopener noreferrer"
          style={{
            flexDirection: 'column',
            marginTop: '12px'
          }}
        >
          {
            hasWEBPSupport !== '' && (
              <div
                className="insurance__sprite--image"
                id={item.id}
                style={{
                  backgroundImage: `url(${hasWEBPSupport ? item.spriteURL : item.spriteFallbackURL})`,
                  backgroundPosition: item.background,
                  backgroundSize: 'cover',
                  display: 'inline-flex',
                  height: '40px',
                  width: '100px',
                  transform: 'scale(1.25)',
                }}
              />
            )
          }
        </a>
      </CarouselSlide>
    ));
  };


  const getRecommendedArticleSlides = (items) => {

    return items.map((item, i) => (
      <CarouselSlide key={i}>
        <div className="article__header">
          <img alt={item.altImage} src={item.image} srcSet={item.image} width={isDesktop ? "262px" : "317px"} height="152px" loading='lazy' />
        </div>
        <div className="article__body">
          <p className="article__body--title">{item.title}</p>
          <p className="article__body--subtitle">{item.description}</p>
          <a className="article__body--link" href={`/media/${item.slug}`}>Baca Selengkapnya</a>
        </div>
      </CarouselSlide>
    ));
  };

  const getFinanicalCheckupCard = () => (
    <div className="financial__checkup--wrapper">
      <div className="financial__artical--group">
        <div className="creative__block">
          <i class="iconSprites iconSprites__rainbowMan" />
        </div>
        <div className="content__block">
          <p className="heading-sm-m">
            Dapatkan Tips dan Inspirasi Perencanaan Keuangan Terbaik
          </p>
          <p className="heading-xs-r secondary__info">
            Tanyakan atau dapatkan tips dan inspirasi untuk perencanaan keuanganmu langsung dari ahlinya.
          </p>
        </div>
        <div className="action__block">
          <Link
            href="/cek-keuangan"
          >
            <a
              className="action__item button-primary"
              role="button"
            >
              Cek Kesehatan Keuanganmu
              <i className="iconSprites iconSprites__arrow__white__right" />
            </a>
          </Link>
          <Link
            href="/tanya/"
          >
            <a
              className="action__item button-default"
              role="button"
            >
              Tanya Seputar keuanganmu
              <i className="iconSprites iconSprites__arrow__blue__right" />
            </a>
          </Link>
        </div>
      </div>
    </div>
  );

  const extraInfoContainer = () => (
    <>
      <div className="landing__extra--info">
        <div className="info">
          <div className="info__counter">
            <div className="count">01</div>
          </div>
          <div className="info__detail">
            <h3 className="info__title heading-sm-m">Ratusan Pilihan Sesuai Kebutuhan</h3>
            <p className="info__description heading-xs-r">
              Berbagai pilihan polis terbaik dari 20+ rekan asuransi terpercaya di Indonesia
            </p>
          </div>
        </div>
        <div className="info">
          <div className="info__counter">
            <div className="count">02</div>
          </div>
          <div className="info__detail">
            <h3 className="info__title heading-sm-m">Diskon Harga Terbaik</h3>
            <p className="info__description heading-xs-r">
              Harga terbaik yang Anda bisa dapatkan dibanding kompetitor-kompetitor
            </p>
          </div>
        </div>
        <div className="info">
          <div className="info__counter">
            <div className="count">03</div>
          </div>
          <div className="info__detail">
            <h3 className="info__title heading-sm-m">Layanan Gratis Hingga Klaim</h3>
            <p className="info__description heading-xs-r">
              Setiap pembelian di Lifepal akan mendapatkan layanan gratis
            </p>
          </div>
        </div>
        <div className="info">
          <div className="info__counter">
            <div className="count">04</div>
          </div>
          <div className="info__detail">
            <h3 className="info__title heading-sm-m">Proses Praktis dan Mudah</h3>
            <p className="info__description heading-xs-r">
              Tak perlu repot untuk memproses polis karena sudah 100% kami urus
            </p>
          </div>
        </div>
      </div>
    </>
  );

  // The leadform popup and WA leadform popup has different design
  const [showPopup, setShowPopup] = useState(false); //leadform popup
  const [hasClosedPopup, setHasClosedPopup] = useState(false); //State to set the popup won't show again
  const [showWApopup, setShowWApopup] = useState(false); //WA leadform popup after WAbtn click
  const [leadSubmitLoading, setLeadSubmitLoading] = useState(false);

  const leadFormHandler = async (values, isWhatsapp) => {
    setLeadSubmitLoading(true);
    const name = values.fullName ? values.fullName.split(' ') : '';
    const firstName = name.length > 0 ? name.shift() : name;
    const lastName = name.length > 0 ? name.join(' ') : '';

    const setWAinsuranceType = (type) => {
      switch (type) {
        case 'health': return 'kesehatan';
        case 'life': return 'jiwa';
        case 'auto': return 'mobil';
        default: return '';
      }
    };

    if (isWhatsapp) {
      if (values.email) {
        await addRecepient([{
          first_name: firstName,
          last_name: lastName,
          email: values.email,
          source: 'sticky_whatsapp',
        }])
          .then(async (resp) => {
            if (resp) {
              await addSendGridList([{
                id: resp.persisted_recipients[0]
              }]);
            }
          });
      }

      sendTracker(
        'event',
        'lead-submit',
        'whatsapp-popup',
        values.insuranceType
      );
      const text =
        `Halo, saya memiliki pertanyaan tentang produk asuransi ${setWAinsuranceType(values.insuranceType)}` +
        "\n" +
        `Nama: ${values.fullName}` +
        "\n" +
        `Kategori Asuransi: ${setWAinsuranceType(values.insuranceType)}` +
        "\n" +
        `Email: ${values.email}`
        ;
      const whatsappNumber = values.insuranceType === 'auto' ? '6285649031559' : '6281284570583';
      const WhatsAppAPI = `https://api.whatsapp.com/send/?phone=${whatsappNumber}&text=${encodeURIComponent(text)}&app_absent=0`;
      Router.push(WhatsAppAPI);
    } else {
      const payload = {
        category: values.insuranceType,
        phone_number: values.phoneNumber ? cleanPhoneNumber(values.phoneNumber).replace(/\s/g, '') : '',
        first_name: firstName,
        last_name: lastName,
        email: values.email ? values.email : '',
        lead_info_data: {
          source: 'core',
          conversion_page: `${window.location.pathname}`,
        },
      }


      const gaId = tomiAI.getGaId();
      const landing_page = cookies.get('landing_page');

      if (gaId) {
        payload.utm_data = payload.utm_data || {};
        payload.utm_data.ga_id = gaId;
      }

      if (landing_page) {
        payload.lead_info_data = payload.lead_info_data || {};
        payload.lead_info_data.landing_page = landing_page;
      }

      const response = await NextServerAPI({
        method: 'POST',
        path: '/api/leads/list/',
        data: payload
      });

      if (response && response.data) {
        const { data } = response;
        setLeadSubmitLoading(false);

        cookies.set("lead_id_cookie", data.id, { expires: 30 });
        sendTracker(
          'event',
          'lead-submit',
          'popup',
          response.data.category
        );
        const queryParams = {
          category: data.category,
          phoneNumber: values.phoneNumber,
          fullName: `${data.first_name} ${data.last_name}`,
          step: 1,
          selfChecked: true,
        };

        Router.push({
          pathname: '/search',
          query: queryParams
        })
      } else {
        toast.error('Terjadi kesalahan pada sistem kami, silakan coba lagi');
        setLeadSubmitLoading(false);
      }
    }
  };

  const showLeadPopup = (type) => {
    const isWA = type === 'whatsapp';

    return (
      <LandingLeadForm
        handleSubmit={(values) => leadFormHandler(values, isWA)}
        loading={leadSubmitLoading}
        isPopup={true}
        isWhatsapp={isWA}
        closePopup={() => {
          setHasClosedPopup(true);
          isWA ? setShowWApopup(false) : setShowPopup(false)
        }}
      />
    );
  }

  const handleHeroCategoryClick = ({ authToken, category, url }) => {
    let pushUrl = '/asuransi';

    if (authToken) {
      if (userProfile && userProfile.fullName && userProfile.phoneNumber) {
        const trackerPayload = {
          phone_number: userProfile.phoneNumber,
          category: category
        }
  
        dispatch({
          type: 'CREATE_QUEUE_ENTRY',
          payload: {
            data: trackerPayload,
          },
          callback: (response) => {
            if (response && response.data && response.data.id) {
              localStorage.setItem('queueId', response.data.id);
            }
          }
        })
        
        const query = {
          category: category,
          step: 1,
          name: userProfile.fullName,
          phoneNumber: userProfile.phoneNumber,
          has_credit_card: false,
        };
  
        pushUrl = `/search?${qs.stringify(query)}`;
      }
  
    } else {
      pushUrl = url;
    }
    
    Router.push(pushUrl)
  }

  return (
    <>
      <style>
        {
          `
            .app__main {
              overflow-x: hidden
            }
          `
        }
      </style>
      <Meta
        title="Lifepal: Broker Asuransi No. 1 di Indonesia"
        meta={[
          <meta
            key="description"
            name="description"
            content="Bandingkan berbagai pilihan polis asuransi terbaik di Indonesia secara online di Lifepal. Dapatkan promo menarik dan premi paling terjangkau! "
          />,
          <meta
            key="keywords"
            name="keywords"
            content="asuransi, asuransi kesehatan, asuransi jiwa"
          />,
          <meta
            key="ogimage"
            property="og:image"
            content="/static/images/lifepal-logo-square.jpg"
          />,
          <link
            key="canonical"
            rel="canonical"
            href={APP_ENV === 'production' ? 'https://lifepal.co.id' : 'https://lifepal.tech/'}
          />,
          APP_ENV !== 'develop' && <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7440190460548738" crossOrigin="anonymous" />
        ]}
      />
      <div className="container landing__page--wrapper" data-testid="landing__page--test">

        <HeroLanding
          loginMode={authToken ? true : false}
          mainTitle="Cari Asuransi Terbaik Sesuai Kebutuhan dan Anggaranmu"
          all="/static/images/products/all.webp"
          hasWEBPSupport={hasWEBPSupport}
          insuranceItems={[
            {
              id: 1,
              title: 'Asuransi Mobil',
              backgroundPosition: '40px 200px',
              onClick: () => handleHeroCategoryClick({
                authToken,
                category: 'auto',
                url: '/asuransi/mobil/'
              }),
            },
            {
              id: 2,
              title: 'Asuransi Kesehatan',
              backgroundPosition: '40px 360px',
              onClick: () => handleHeroCategoryClick({
                authToken,
                category: 'health',
                url: '/asuransi/kesehatan/'
              }),
            },
            {
              id: 3,
              title: 'Asuransi Jiwa',
              backgroundPosition: '40px 240px',
              onClick: () => handleHeroCategoryClick({
                authToken,
                category: 'life',
                url: '/asuransi/jiwa/'
              }),
            },
            {
              id: 4,
              title: 'Asuransi Karyawan',
              backgroundPosition: '40px 280px',
              onClick: () => {
                if (authToken) {
                  window.location.href = 'https://app.lifepal.co.id/'
                } else {
                  handleHeroCategoryClick({
                    url: '/asuransi/jiwa/'
                  })
                }
              },
            }
          ]}
          additionalInsuranceItems={[
            {
              id: 5,
              title: 'Asuransi Properti',
              backgroundPosition: '40px 40px',
              onClick: () => handleHeroCategoryClick({
                authToken,
                category: 'property',
                url: '/asuransi/properti/'
              }),
            },
            {
              id: 6,
              title: 'Asuransi Motor',
              backgroundPosition: '40px 160px',
              onClick: () => handleHeroCategoryClick({
                authToken,
                category: 'moto',
                url: '/asuransi/motor/'
              }),
            },
            {
              id: 7,
              title: 'Asuransi Perjalanan',
              backgroundPosition: '40px 120px',
              onClick: () => handleHeroCategoryClick({
                authToken,
                category: 'travel',
                url: '/asuransi/perjalanan/'
              }),
            },
            {
              id: 8,
              title: 'Kesehatan Syariah',
              backgroundPosition: '40px 320px',
              onClick: () => handleHeroCategoryClick({
                authToken,
                category: 'syariah',
                url: '/asuransi/syariah/'
              }),
            },
            {
              id: 9,
              title: 'Kesehatan Keluarga',
              backgroundPosition: '40px 360px',
              onClick: () => handleHeroCategoryClick({
                authToken,
                category: 'health',
                url: '/asuransi/kesehatan-keluarga/'
              }),
            },
            {
              id: 10,
              title: 'Premi Kembali',
              backgroundPosition: '40px 80px',
              onClick: () => handleHeroCategoryClick({
                authToken,
                category: 'life',
                url: '/asuransi/jiwa-rop/'
              }),
            },
          ]}
        />

        {!authToken && <div className="landing__carousel">
          <Carousel
            items={[
              {
                id: 0,
                imgURL: '/static/images/promos/home_banner1.png',
                fallbackImgURL: '/static/images/promos/home_banner1.png',
                alt: 'Promo Merdeka',
                redirectURL: '/asuransi/mobil/?utm_source=homepage&utm_medium=banner&utm_campaign=MV_Savetember',
                loading: 'lazy'
              },
              {
                id: 1,
                imgURL: '/static/images/promos/home_banner2.png',
                fallbackImgURL: '/static/images/promos/home_banner2.png',
                alt: 'Promo Asuransi Kesehatan',
                redirectURL: '/asuransi/kesehatan/?utm_source=homepage&utm_medium=banner&utm_campaign=promo_asuransi_kesehatan',
                loading: 'lazy'
              },
              {
                id: 2,
                imgURL: '/static/images/promos/home_banner3.png',
                fallbackImgURL: '/static/images/promos/home_banner3.png',
                alt: 'Promo 15 Tahun',
                redirectURL: '/asuransi/mobil/?utm_source=homepage&utm_medium=banner&utm_campaign=MV_promo_voucher',
                loading: 'lazy'
              },
              {
                id: 3,
                imgURL: '/static/images/promos/home_banner4.png',
                fallbackImgURL: '/static/images/promos/home_banner4.png',
                alt: 'Promo Asuransi Kesehatan Keluarga',
                redirectURL: '/asuransi/kesehatan-keluarga/?utm_source=homepage&utm_medium=banner&utm_campaign=promo_asuransi_keluarga',
                loading: 'lazy'
              },
              {
                id: 4,
                imgURL: '/static/images/promos/home_banner5.png',
                fallbackImgURL: '/static/images/promos/home_banner5.png',
                alt: 'Bandingkan Asuransi Terbaik',
                redirectURL: '/asuransi/mobil/?utm_source=homepage&utm_medium=banner&utm_campaign=homepage+compare',
                loading: 'lazy'
              },
              {
                id: 5,
                imgURL: '/static/images/promos/home_banner6.png',
                fallbackImgURL: '/static/images/promos/home_banner6.png',
                alt: 'Perbaikan di Bengkel Resmi',
                redirectURL: '/asuransi/mobil/?utm_source=homepage&utm_medium=banner&utm_campaign=MV_cicilan',
                loading: 'lazy'
              }
            ]}
            slides={getPromosSlides}
            isPlaying={true}
            defaultVisibleSlide={(isDesktop || isTablet) ? 4 : 1}
            className="promo__slides"
            centerMode={true}
            dotGroup={authToken ? false : true}
            title="Promo Asuransi Sesuai Kebutuhanmu"
            titleH2="Promo Asuransi Sesuai Kebutuhanmu"
            titlePosition="center"
          />
        </div>}


        {!authToken && <div className="landing__carousel__small">
          <Carousel
            items={[
              {
                id: 1,
                imgUrl: "",
                alt: "garda-oto",
                title: "Garda Oto",
                url: "/asuransi/mobil-garda-oto/",
                background: '104px 0px',
                spriteURL: '/static/images/brands/brands_1.webp',
                spriteFallbackURL: '/static/images/brands/brands.png',
                loading: 'lazy'
              },
              {
                id: 2,
                className: 'mega-insurance',
                alt: "mega-insurance",
                title: "Mega Insurance",
                star: "4.3",
                url: "/asuransi/mobil-mega-insurance/",
                background: '100px 1759px',
                spriteURL: '/static/images/brands/brands.webp',
                spriteFallbackURL: '/static/images/brands/brands.png',
                loading: 'lazy'
              },
              {
                id: 3,
                className: 'mag',
                alt: "mag",
                title: "Mag",
                url: "/asuransi/mobil-mag/",
                background: '100px 1837px',
                spriteURL: '/static/images/brands/brands.webp',
                spriteFallbackURL: '/static/images/brands/brands.png',
                loading: 'lazy'
              },
              {
                id: 4,
                alt: 'zurich-syariah',
                title: 'Zurich Syariah',
                url: '/asuransi/zurich-syariah/',
                background: '100px 1599px',
                spriteURL: '/static/images/brands/brands.webp',
                spriteFallbackURL: '/static/images/brands/brands.png',
                loading: 'lazy'
              },
              {
                id: 5,                
                imgUrl: '/static/images/brands/aca.svg',
                alt: 'aca',
                title: 'ACA',
                star: '4.2',
                url: '/asuransi/mobil-aca/',
                background: '204px 1000px',
                spriteURL: '/static/images/brands/brands.webp',
                spriteFallbackURL: '/static/images/brands/brands.png',
                loading: 'lazy'
              },              
              {
                id: 6,
                alt: "simas-insurtech",
                title: "Simas Insurtech",
                url: "/asuransi/simas-insurtech/",
                background: '104px 80px',
                spriteURL: '/static/images/brands/brands.webp',
                spriteFallbackURL: '/static/images/brands/brands.png',
                loading: 'lazy'
              },
              {
                id: 7,
                alt: 'axa-mandiri',
                title: 'AXA Mandiri',
                url: '/asuransi/kesehatan-axa-mandiri/',
                background: '100px -42px',
                spriteURL: '/static/images/brands/brands.webp',
                spriteFallbackURL: '/static/images/brands/brands.png',
                loading: 'lazy'
              },
              {
                id: 8,
                imgUrl: "/static/images/brands/sunlife.png",
                alt: 'sunlife',
                title: 'Sunlife',
                star: '4.3',
                url: '/asuransi/sunlife/',
                background: '204px 598px',
                spriteURL: '/static/images/brands/brands.webp',
                spriteFallbackURL: '/static/images/brands/sunlife.png',
                loading: 'lazy'
              },
              {
                id: 9,
                className: 'lippo-insurance',
                alt: "lippo-insurance",
                title: "Lippo Insurance",
                star: "4.3",
                url: "/asuransi/kesehatan-lippo/",
                background: '100px 840px',
                spriteURL: '/static/images/brands/brands.webp',
                spriteFallbackURL: '/static/images/brands/brands.png',
                loading: 'lazy'
              },
              {
                id: 10,
                className: 'kb-insurance',
                alt: "kb-insurance",
                title: "KB Insurance",
                star: "4.3",
                url: "/asuransi/kb-insurance/",
                background: '100px 1799px',
                spriteURL: '/static/images/brands/brands.webp',
                spriteFallbackURL: '/static/images/brands/brands.png',
                loading: 'lazy'
              },
              {
                id: 11,
                imgUrl: "",
                alt: "car-life",
                title: "Car Life",
                url: "/asuransi/kesehatan-car/",
                background: '204px 1200px',
                spriteURL: '/static/images/brands/brands.webp',
                spriteFallbackURL: '/static/images/brands/brands.png',
                loading: 'lazy'
              },
              {
                id: 12,
                imgUrl: "",
                alt: "april-international",
                title: "April International",
                url: "/asuransi/april-international/",
                background: '100px 120px',
                spriteURL: '/static/images/brands/brands_1.webp',
                spriteFallbackURL: '/static/images/brands/brands.png',
                loading: 'lazy'
              },
            ]}
            slides={getInsuraceSlides}
            isPlaying={false}
            infinite={false}
            defaultVisibleSlide={(isDesktop || isTablet) ? 6 : 2}
            className="insurance__slides"
            centerMode={true}
            dotGroup={authToken ? false : true}
            title="Bekerjasama Dengan Asuransi Terpercaya"
            titleH2="Bekerjasama Dengan Asuransi Terpercaya"
            titlePosition="center"
          />
        </div>}


        {!authToken && <GoogleReview />}

        {!authToken && showPopup && !hasClosedPopup && showLeadPopup('')}
        {!authToken && showWApopup && showLeadPopup('whatsapp')}

        {!authToken && extraInfoContainer()}
        {!authToken && <StickyWhatsapp />}

        {!authToken &&
          <div className="landing__carousel">
            <Carousel
              items={[
                {
                  id: 1,
                  alt: 'review',
                  name: 'Tobias Wijaya',
                  review: `
                      Lewat Lifepal nyari asuransi jadi mudah karena tinggal bandingin aja. Penjelasan di websitenya mudah dimengerti dan transparan. Saya mencari asuransi kesehatan yang bisa cover sekeluarga, lumayan dapat diskon dari Lifepal. Mantep. 
                    `,
                },
                {
                  id: 2,
                  alt: 'review',
                  name: 'Abdi Surya',
                  review: `
                      Saya mencari asuransi untuk mobil dan udah cek harga di beberapa tempat. Ternyata di Lifepal yang paling lengkap pilihannya, dan dikasi diskon gede paling murah. So far saya sangat puas dengan pelayanannya. 
                    `,
                },
                {
                  id: 3,
                  alt: 'review',
                  name: 'Endro Bayu',
                  review: `
                      Sempat mengajukan klaim dan ditolak oleh pihak asuransi. Tapi dari Lifepal bantu ngurusin dan berdebat dengan pihak asuransi. Terima kasih banget, akhir dicover penuh. Saya bakal rekomendasiin Lifepal ke teman-teman saya. 
                    `,
                },
                {
                  id: 4,
                  alt: 'review',
                  name: 'Laurenchia Kairen',
                  review: `
                      Happy of Lifepal! Sangat mempermudah pembelian asuransi, banyak pilihan, harganya murah, bahkan cicilan 0%. Yang paling berkesan itu sales dan customer servicenya yang sabar menjelaskan walaupun saya banyak pertanyaan. Good! 
                    `,
                },
                {
                  id: 5,
                  alt: 'review',
                  name: 'Nandya Utami',
                  review: `
                      Memudahkan banget buat pemula seperti saya yang mencari asuransi sesuai kebutuhan dengan anggaran ngepas. Abis isi form langsung dikasi lihat semua pilihan polis dengan transparan. Salut si.
                    `,
                },
                {
                  id: 6,
                  alt: 'review',
                  name: 'Saras Utami',
                  review: `
                      Saya bingung pas dijelasin sama agen asuransi, jadi saya baca sendiri aja di Lifepal. Semua informasinya udah jelas jadi lebih percaya buat beli. Semoga bisa terus dipertahankan kemudahan transaksi dan transparansinya. Thx
                    `,
                },
              ]}
              slides={getAboutSlides}
              isPlaying={true}
              infinite={true}
              dotGroup={true}
              defaultVisibleSlide={(isDesktop || isTablet) ? 2 : 1}
              className="about__slides"
              centerMode={true}
              title="Apa Kata Mereka Tentang Lifepal?"
              titleH2="Apa Kata Mereka Tentang Lifepal?"
              titlePosition="center"
            />
          </div>
        }

        <div className="blog">
          <div className="blogs">
            <BlogPost
              hasWEBPSupport={hasWEBPSupport}
              medias={[
                {
                  id: '0',
                  iconSprites: 'iconSprites__asuransi',
                  title: 'Asuransi',
                  link: '/media/category/perencanaan/asuransi/',
                  loading: 'lazy'
                },
                {
                  id: '1',
                  iconSprites: 'iconSprites__asuransi--kesehatan',
                  title: 'Asuransi Kesehatan',
                  link: '/media/category/asuransi/asuransi-kesehatan/',
                  loading: 'lazy'
                },
                {
                  id: '2',
                  iconSprites: 'iconSprites__asuransi--mobil',
                  title: 'Asuransi Mobil',
                  link: '/media/category/asuransi/asuransi-kendaraan/asuransi-mobil/',
                  loading: 'lazy'
                },
                {
                  id: '3',
                  iconSprites: 'iconSprites__biaya__kesehatan',
                  title: 'Biaya Kesehatan',
                  link: '/media/category/kesehatan/biaya-kesehatan/',
                  loading: 'lazy'
                },
                {
                  id: '4',
                  iconSprites: 'iconSprites__perbaikan__kendaraan',
                  title: 'Perbaikan Kendaraan',
                  link: '/media/category/kendaraan/perbaikan-kendaraan/',
                  loading: 'lazy'
                },
                {
                  id: '5',
                  iconSprites: 'iconSprites__rumah__sakit',
                  title: 'Rumah Sakit',
                  link: '/media/category/kesehatan/rumah-sakit/',
                  loading: 'lazy'
                },
              ]}
            />
          </div>
        </div>

        <div className="landing__carousel">
          <Carousel
            items={MEDIA_RECOMMENDED_ARTICLES}
            slides={getRecommendedArticleSlides}
            isPlaying={true}
            infinite={true}
            defaultVisibleSlide={(isDesktop || isTablet) ? 4 : 1}
            className="article__slides"
            centerMode={true}
            dotGroup={authToken ? false : true}
            title="Artikel Rekomendasi"
            titleH2="Artikel Rekomendasi"
            titlePosition="center"
          />
        </div>

        {!authToken &&
          <div id="media__carousel-slides" ref={mediaSlidercontainerRef} className="landing__carousel">
            <Carousel
              items={[
                {
                  id: 1,
                  alt: 'antara-news',
                  background: '100px 720px',
                  spriteURL: '/static/images/media/media.webp',
                  spriteFallbackURL: '/static/images/media/media.png',
                  loading: 'lazy'
                },
                {
                  id: 2,
                  alt: 'berita-satu',
                  background: '100px 680px',
                  spriteURL: '/static/images/media/media.webp',
                  spriteFallbackURL: '/static/images/media/media.png',
                  loading: 'lazy'
                },
                {
                  id: 3,
                  alt: 'viva',
                  background: '100px 640px',
                  spriteURL: '/static/images/media/media.webp',
                  spriteFallbackURL: '/static/images/media/media.png',
                  loading: 'lazy'
                },
                {
                  id: 4,
                  alt: 'jpnn',
                  background: '100px 600px',
                  spriteURL: '/static/images/media/media.webp',
                  spriteFallbackURL: '/static/images/media/media.png',
                },
                {
                  id: 5,
                  alt: 'inilah',
                  background: '100px 560px',
                  spriteURL: '/static/images/media/media.webp',
                  spriteFallbackURL: '/static/images/media/media.png',
                  loading: 'lazy'
                },
                {
                  id: 6,
                  alt: 'kontan',
                  background: '100px 520px',
                  spriteURL: '/static/images/media/media.webp',
                  spriteFallbackURL: '/static/images/media/media.png',
                },
                {
                  id: 7,
                  alt: 'bisniscom',
                  background: '100px 480px',
                  spriteURL: '/static/images/media/media.webp',
                  spriteFallbackURL: '/static/images/media/media.png',
                  loading: 'lazy'
                },
                {
                  id: 8,
                  alt: 'tribunnews',
                  background: '100px 440px',
                  spriteURL: '/static/images/media/media.webp',
                  spriteFallbackURL: '/static/images/media/media.png',
                  loading: 'lazy'
                },
                {
                  id: 9,
                  alt: 'viva',
                  background: '100px 400px',
                  spriteURL: '/static/images/media/media.webp',
                  spriteFallbackURL: '/static/images/media/media.png',
                  loading: 'lazy'
                },
                {
                  id: 10,
                  alt: 'sindonews',
                  background: '100px 360px',
                  spriteURL: '/static/images/media/media.webp',
                  spriteFallbackURL: '/static/images/media/media.png',
                  loading: 'lazy'
                },
                {
                  id: 11,
                  alt: 'liputan',
                  background: '100px 320px',
                  spriteURL: '/static/images/media/media.webp',
                  spriteFallbackURL: '/static/images/media/media.png',
                  loading: 'lazy'
                },
                {
                  id: 12,
                  alt: 'suaracom',
                  background: '100px 280px',
                  spriteURL: '/static/images/media/media.webp',
                  spriteFallbackURL: '/static/images/media/media.png',
                  loading: 'lazy'
                },
                {
                  id: 13,
                  alt: 'suaramerdeka',
                  background: '100px 240px',
                  spriteURL: '/static/images/media/media.webp',
                  spriteFallbackURL: '/static/images/media/media.png',
                  loading: 'lazy'
                },
                {
                  id: 14,
                  alt: 'detikcom',
                  background: '100px 200px',
                  spriteURL: '/static/images/media/media.webp',
                  spriteFallbackURL: '/static/images/media/media.png',
                  loading: 'lazy'
                },
                {
                  id: 15,
                  alt: 'jawaposcom',
                  background: '100px 160px',
                  spriteURL: '/static/images/media/media.webp',
                  spriteFallbackURL: '/static/images/media/media.png',
                  loading: 'lazy'
                },
                {
                  id: 16,
                  alt: 'kumparan',
                  background: '100px 120px',
                  spriteURL: '/static/images/media/media.webp',
                  spriteFallbackURL: '/static/images/media/media.png',
                  loading: 'lazy'
                },
                {
                  id: 17,
                  alt: 'kompascom',
                  background: '100px 80px',
                  spriteURL: '/static/images/media/media.webp',
                  spriteFallbackURL: '/static/images/media/media.png',
                  loading: 'lazy'
                },
                {
                  id: 18,
                  alt: 'tempoco',
                  background: '100px 40px',
                  spriteURL: '/static/images/media/media.webp',
                  spriteFallbackURL: '/static/images/media/media.png',
                  loading: 'lazy'
                },
              ]}
              slides={getMediaSlides}
              isPlaying={true}
              infinite={true}
              defaultVisibleSlide={(isDesktop || isTablet) ? 6 : 2}
              className="product__slides"
              centerMode={true}
              dotGroup={true}
              title="Telah Diliput Oleh"
              titleH2="Telah Diliput Oleh"
              titlePosition="center"
            />
          </div>
        }

        {!authToken &&
          <div className="faq mb-4">
            <p style={{ 'lineHeight': '24px', 'color': '#5e5e5e', 'marginBottom': '10px' }}>
              Lifepal adalah platform all-in-one (marketplace asuransi online terbesar di Indonesia) untuk membandingkan polis dan beli asuransi online. Dapatkan pertanggungan maksimal dengan premi termurah dan terbaik {new Date().getFullYear()}!
            </p>
            <SEOAccordion
              isMultipleOpen={true}
              accordionList={[
                {
                  id: '1',
                  title: "Apa itu asuransi?",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>
                        Asuransi adalah perjanjian kontrak antara pihak perusahaan asuransi dan tertanggung atau nasabah. Cara kerjanya tertanggung wajib membayarkan iuran atau premi untuk mendapatkan penggantian finansial atas risiko kerugian dari perusahaan asuransi.
                      </p>
                      <p>
                        Karena itu bisnis asuransi merupakan salah satu bentuk usaha yang termasuk dalam bidang jasa pertanggungan atau pertanggungan ulang risiko finansial, sehingga disebut juga sebagai proteksi finansial.
                      </p>
                    </>
                  ),
                },
                {
                  id: '2',
                  title: "Apa fungsi dari asuransi?",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>
                        Fungsi asuransi adalah sebagai pengalihan kerugian finansial. Misal, jika Anda memiliki asuransi mobil dan kendaraan yang diasuransikan mengalami tabrakan dan membutuhkan biaya perbaikan cukup mahal. Di sini peran asuransi mobil untuk menanggung biaya kerugian tersebut, jadi nasabah atau tertanggung tidak perlu khawatir dan harus menggunakan dana tabungan untuk risiko tersebut.
                      </p>
                      <p>
                        Sama halnya dengan asuransi kesehatan dan asuransi jiwa. Di mana fungsi asuransi kesehatan menanggung biaya perawatan medis di rumah sakit terbaik pilihan nasabah dengan proses administratif yang sederhana.
                      </p>
                      <p>
                        Sementara fungsi asuransi jiwa untuk membantu finansial keluarga tetap dapat berjalan baik meski tertanggung atau tulang punggung keluarga mengalami risiko meninggal dunia.
                      </p>
                    </>
                  ),
                },
                {
                  id: '3',
                  title: "Apa saja jenis-jenis asuransi di Indonesia?",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>
                        Jenis asuransi di Indonesia dibedakan menjadi tiga, yaitu asuransi umum, asuransi jiwa, dan produk reasuransi.
                      </p>
                      <ul>
                        <li>
                          <strong>Perusahaan asuransi umum: </strong>
                          memberikan ganti rugi finansial atas kerusakan, kehilangan suatu objek, atau tanggung jawab hukum pihak ketiga atas kejadian yang tidak disengaja. Jenis asuransi umum mencakup asuransi mobil, asuransi bisnis, dan asuransi properti.
                        </li>
                        <li>
                          <strong>Perusahaan asuransi jiwa: </strong>
                          memberikan pertanggungan biaya atas risiko yang menyangkut kesehatan atau jiwa nasabah. Jenis asuransi ini mencakup asuransi kesehatan dan asuransi jiwa baik untuk tertanggung individu maupun karyawan.
                        </li>
                        <li>
                          <strong>Perusahaan reasuransi: </strong>
                          memberikan pertanggungan ulang jika perusahaan asuransi mengalami risiko kerugian atau bangkrut.
                        </li>
                      </ul>
                    </>
                  ),
                },
                {
                  id: '4',
                  title: "Apa saja jenis-jenis asuransi kesehatan?",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>
                        Asuransi kesehatan terbagi menjadi beberapa kategori, yaitu:
                      </p>
                      <ul>
                        <li>
                          <a href="/asuransi/hospital-cash-plan/" target="_blank" rel="noreferrer noopener"> Asuransi hospital cash plan</a>: asuransi kesehatan yang memberikan uang santunan harian ketika tertanggung dirawat inap.
                        </li>
                        <li>
                          Asuransi karyawan: asuransi kesehatan yang dikhususkan untuk tertanggung karyawan perusahaan. Biasanya menanggung biaya rawat jalan.
                        </li>                        
                        <li>
                          Asuransi keluarga: asuransi kesehatan yang dapat menanggung umumnya hingga 5 anggota keluarga dalam satu polis sekaligus.
                        </li>
                        <li>
                          Asuransi kesehatan tambahan (Rider): asuransi gigi dan asuransi melahirkan.
                        </li>
                      </ul>
                    </>
                  ),
                },
                {
                  id: '5',
                  title: "Apa saja jenis-jenis asuransi jiwa?",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>
                        Jenis-jenis asuransi jiwa terbagi menjadi beberapa kategori, yaitu:
                      </p>
                      <ul>
                        <li>
                          Asuransi jiwa berjangka (term-life): produk asuransi jiwa yang memberikan pilihan masa polis dalam jangka waktu tertentu.
                        </li>
                        <li>
                          Asuransi jiwa seumur hidup (whole-life): proteksi asuransi jiwa hingga tertanggung mencapai usia 100 tahun.
                        </li>
                        <li>
                          <a href="/asuransi/dwiguna" target="_blank" rel="noreferrer noopener"> Asuransi jiwa dwiguna </a>(asuransi tabungan): produk asuransi jiwa dengan tambahan manfaat tabungan.
                        </li>
                        <li>
                          Asuransi investasi (unit link): produk asuransi jiwa yang memberikan santunan meninggal dunia dan nilai tunai investasi sekaligus.
                        </li>
                        <li>
                          Asuransi penyakit kritis: bagian dari manfaat tambahan asuransi jiwa yang memberikan uang pertanggungan jika tertanggung terdiagnosa penyakit kritis.
                        </li>
                      </ul>
                    </>
                  ),
                },
                {
                  id: '6',
                  title: "Apa saja jenis-jenis asuransi mobil?",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>
                        Jenis polis asuransi mobil terbagi menjadi dua kategori, yaitu:
                      </p>
                      <ul>
                        <li>
                          <a href="/asuransi/all-risk" target="_blank" rel="noreferrer noopener"> Asuransi All Risk</a>: memberikan ganti rugi mulai dari kerusakan parsial seperti baret, penyok, hingga rusak total hingga kehilangan akibat pencurian.
                        </li>
                        <li>
                          <a href="/asuransi/tlo" target="_blank" rel="noreferrer noopener"> Asuransi Total Loss Only (TLO)</a>: memberikan ganti rugi tas kerugian total atau nilai perbaikan sama atau lebih dari 75% harga mobil.
                        </li>
                      </ul>
                    </>
                  ),
                },
                {
                  id: '7',
                  title: "Apa saja jenis-jenis asuransi mikro?",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>
                        Jenis polis asuransi yang bisa didapat dengan premi sangat terjangkau:
                      </p>
                      <ul>
                        <li>
                          <a href="/asuransi/kecelakaan" target="_blank" rel="noreferrer noopener"> Asuransi Kecelakaan</a>: manfaat asuransi yang bisa dijadikan pelengkap untuk manfaat asuransi mobil.
                        </li>
                        <li>
                          Asuransi penyakit tropis: pertanggungan biaya perawatan kesehatan jika nasabah terdiagnosa penyakit tropis, seperti DBD.
                        </li>
                      </ul>
                    </>
                  ),
                },
                {
                  id: '8',
                  title: "Apa perbedaan asuransi syariah dan konvensional?",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>
                        Terdapat dua pilihan perusahaan asuransi yang tersedia di Indonesia, yaitu asuransi syariah dan asuransi konvensional. Secara singkat, asuransi Islam disebut juga dengan istilah <a href="/asuransi/syariah" target="_blank" rel="noreferrer noopener">asuransi syariah</a>. Artinya, setiap perusahaan asuransi syariah diawasi oleh Dewan Pengawas Syariah (DPS) untuk memastikan pengelolaan dana perusahaan asuransi sesuai dengan syariat Islam, yaitu tolong menolong.
                      </p>
                      <p>
                        Sementara asuransi konvensional mengikuti sistem <em>transfer of risk</em>, dimana perjanjian asuransi berfokus pada jual-beli. Berikut ini tabel perbedaan asuransi syariah dan konvensional:
                      </p>
                      <table>
                        <tr>
                          <th><strong>Perbedaan</strong></th>
                          <th><strong>Asuransi Syariah</strong></th>
                          <th><strong>Asuransi Konvensional</strong></th>
                        </tr>
                        <tr>
                          <td>Pengelolaan risiko</td>
                          <td>Saling tolong, saling menjamin, dan bekerja sama lewat kontribusi dana hibah (premi). Prinsipnya berbagi risiko antara perusahaan asuransi dan peserta.</td>
                          <td>Sistem yang berlaku adalah transfer of risk. Risiko yang dialami pemegang polis atau tertanggung dibebankan kepada perusahaan asuransi.</td>
                        </tr>
                        <tr>
                          <td>Pengelolaan dana</td>
                          <td>Pengelolaan dana bersifat transparan dan penggunaannya untuk kebaikan pemegang polis.</td>
                          <td>Pengelolaan dana bersifat tertutup dan perusahaan yang menentukan jumlah premi dan biaya lain. Keuntungan hanya dinikmati perusahaan asuransi.</td>
                        </tr>
                        <tr>
                          <td>Sistem perjanjian</td>
                          <td>Perjanjian dalam asuransi syariah disebut akad asuransi syariah berdasarkan sistem syariah.</td>
                          <td>Dalam asuransi konvensional, perjanjian asuransi seperti perjanjian jual-beli.</td>
                        </tr>
                        <tr>
                          <td>Kepemilikan dana</td>
                          <td>Dana dimiliki bersama oleh pemegang polis. Perusahaan asuransi hanya bertindak sebagai pengelola dana.</td>
                          <td>Dana dari premi yang dibayar tertanggung dimiliki sepenuhnya oleh perusahaan asuransi. Perusahaan bertindak penuh sebagai pengelola untuk mengalokasikan dana.</td>
                        </tr>
                        <tr>
                          <td>Pembagian keuntungan</td>
                          <td>Keuntungan dibagikan kepada semua peserta asuransi (pemegang polis).</td>
                          <td>Seluruh keuntungan menjadi hak milik perusahaan asuransi.</td>
                        </tr>
                        <tr>
                          <td>Kewajiban zakat</td>
                          <td>Perusahaan mewajibkan peserta membayar zakat dengan jumlah sesuai besaran keuntungan yang diperoleh perusahaan.</td>
                          <td>Tidak ada ketentuan terkait zakat.</td>
                        </tr>
                        <tr>
                          <td>Pengawasan</td>
                          <td>Pengawasan dilakukan DPS yang dibentuk DSN dari MUI. Pengawasan termasuk alokasi dana dan investasi yang harus halal. Pengawasan juga dilakukan OJK.</td>
                          <td>Pengawasan hanya dilakukan OJK dan tidak ada kewajiban halal dalam pengelolaannya.</td>
                        </tr>
                        <tr>
                          <td>Dana hangus</td>
                          <td>Ada klaim atau tidak ada pengembalian dana sesuai dengan prinsip bagi hasil termasuk bagi risiko.</td>
                          <td>Hanya produk asuransi konvensional tertentu yang memberikan pengembalian dana (premi). Seperti asuransi jiwa seumur hidup yang hangus jika tertanggung hidup hingga usia 99 tahun</td>
                        </tr>
                        <tr>
                          <td>Instrumen investasi</td>
                          <td>Investasi tidak bisa dilakukan pada kegiatan usaha yang bertentangan dengan prinsip syariah seperti mengandung riba, perjudian, unsur suap, hingga haram.</td>
                          <td>Tidak ada ketentuan syariah, hanya mengutamakan keuntungan sebesar-besarnya.</td>
                        </tr>
                        <tr>
                          <td>Klaim dan layanan</td>
                          <td>Peserta bisa memanfaatkan perlindungan biaya rawat inap untuk semua anggota keluarga dengan premi yang lebih ringan dalam satu polis karena kontribusinya dinilai lebih besar. Memungkinkan klaim ganda dengan asuransi lain yang dimiliki tertanggung.</td>
                          <td>Klaim ganda tersedia pada asuransi konvensional. Namun, tidak semua perusahaan asuransi konvensional memberikan premi murah untuk polis keluarga.</td>
                        </tr>
                      </table>
                    </>
                  ),
                },                
                {
                  id: '9',
                  title: "Apa saja faktor utama yang memengaruhi besaran premi asuransi?",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>
                        Ada banyak yang faktor utama yang perlu diperhatikan. Untuk produk asuransi kesehatan dan asuransi jiwa berikut ini beberapa faktornya:
                      </p>
                      <ul>
                        <li>
                          <strong>Usia tertanggung: </strong>
                          semakin tinggi usia tertanggung, semakin tinggi pula premi yang harus dibayarkan. Umumnya harga premi asuransi akan meningkat ketika tertanggung memasuki usia 30 tahun ke atas.
                        </li>
                        <li>
                          <strong>Uang pertanggungan: </strong>
                          semakin tinggi uang pertanggungan (UP) atau limit asuransi yang dipilih, semakin tinggi pula premi yang harus dibayarkan.
                        </li>
                        <li>
                          <strong>Rumah sakit rekanan: </strong>
                          beberapa perusahaan asuransi juga menanggung biaya perawatan kesehatan di rumah sakit rekanan dalam dan luar negeri. Umumnya, yang menanggung hingga perawatan internasional memiliki premi yang lebih mahal
                        </li>
                        <li>
                          <strong>Jenis asuransi: </strong>
                          asuransi unit link (investasi) juga memiliki premi yang lebih mahal. Sebab, biaya premi yang dibayarkan, sebagian akan digunakan untuk dana investasi.
                        </li>
                      </ul>
                      <p>
                        Sementara untuk asuransi mobil, berikut ini beberapa faktornya:
                      </p>
                      <ul>
                        <li>
                          <strong>Harga kendaraan: </strong>
                          semakin tinggi harga mobil, maka akan semakin mahal pula preminya.
                        </li>
                        <li>
                          <strong>Wilayah domisili: </strong>
                          besaran premi untuk wilayah domisili kendaraan yang diasuransikan telah ditentukan oleh Otoritas Jasa Keuangan (OJK).
                        </li>
                        <li>
                          <strong>Jenis asuransi yang dipilih: </strong>
                          <a
                            title="all risk"
                            href="https://lifepal.co.id/asuransi/all-risk/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            premi asuransi mobil all risk
                          </a> lebih mahal jika dibandingkan Total Loss Only (TLO), sebab manfaat polisnya lebih luas.
                        </li>
                      </ul>
                    </>
                  ),
                },
                {
                  id: '10',
                  title: "Bagaimana cara klaim asuransi kesehatan?",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>
                        Jenis-jenis klaim asuransi kesehatan terbagi menjadi dua, yaitu klaim non tunai (cashless) dan tunai (reimbursement):
                      </p>
                      <ul>
                        <li>
                          <strong>Klaim non tunai (cashless):</strong>{" "}
                          nasabah menunjukkan kartu peserta asuransi di rumah sakit/ klinik rekanan perusahaan asuransi. Setelah itu, tagihan rumah sakit secara otomatis akan ditanggung oleh perusahaan asuransi.
                        </li>
                        <li>
                          <strong>Klaim tunai (reimbursement):</strong>{" "}
                          nasabah membayarkan tagihan rumah sakit dengan dana pribadi di rumah sakit/ klinik non rekanan. Kemudian ajukan klaim, dan pihak rumah sakit akan mengganti dana nasabah yang telah dikeluarkan.
                        </li>
                      </ul>
                    </>
                  ),
                },
                {
                  id: '11',
                  title: "Bagaimana cara klaim asuransi mobil?",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <ol>
                        <li>
                          Segera laporkan klaim maksimal 5x24 jam sejak terjadinya kerugian.
                        </li>
                        <li>
                          Siapkan dokumen klaim seperti SIM, STNK, dokumen polis, formulir klaim.
                        </li>
                        <li>
                          Tim asuransi akan melakukan survei dan analisis untuk menghitung estimasi kerugian.
                        </li>
                        <li>
                          Jika disetujui, mobil dapat diperbaiki di bengkel rekanan.
                        </li>
                        <li>
                          Nasabah harus membayarkan biaya own risk sebesar Rp 300 ribu.
                        </li>
                      </ol>
                    </>
                  ),
                },
                {
                  id: '12',
                  title: "Tips mendapatkan asuransi terbaik di Indonesia?",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>
                        Asuransi terbaik bisa didapat dengan mengetahui kebutuhan dan anggaran Anda saat ini. Kemudian bandingkan beberapa polis dari
                        perusahaan asuransi di Indonesia secara online dan ikuti beberapa tips cara memilih asuransi terbaik berikut ini:
                      </p>
                      <ol>
                        <li>
                          <strong>
                            Pilih manfaat asuransi sesuai dengan kebutuhan
                          </strong>
                          <p>
                            Menyesuaikan manfaat asuransi dengan kebutuhan sangatlah penting untuk mendapatkan polis terbaik
                            dengan premi murah. Misal, untuk sekarang Anda telah memiliki proteksi BPJS Kesehatan dan polis asuransi
                            kesehatan swasta dari kantor. Tapi, karena khawatir polis yang diberikan kantor tidak dapat menanggung
                            biaya penyakit kritis, maka Anda tertarik untuk
                            melengkapinya dengan asuransi kritis.
                          </p>
                          <p>
                            Nah, sebaiknya tidak gegabah dan lakukan tes darah
                            lengkap terlebih dahulu di laboratorium untuk
                            mengetahui risiko kesehatan Anda. Jika hasilnya
                            tidak ada risiko penyakit kritis, sebaiknya tidak
                            perlu melengkapi <em>rider</em> atau polis tambahan
                            asuransi penyakit kritis. Begitupun sebaliknya.
                          </p>
                        </li>
                        <li>
                          <strong>
                            Bandingkan polis secara <em>online</em>
                          </strong>
                          <p>
                            Agar tidak salah pilih, Anda dapat memanfaatkan
                            situs marketplace asuransi seperti Lifepal.co.id untuk membandingkan 500+ polis
                            dari beberapa pilihan perusahaan asuransi di
                            Indonesia. Melalui situs Lifepal, calon nasabah
                            dapat membandingkan detail manfaat hingga premi satu
                            polis dengan lainnya secara mudah.
                          </p>
                        </li>
                        <li>
                          <strong>
                            Pilih manfaat polis yang sebanding dengan biaya rumah sakit
                          </strong>
                          <p>
                            Jangan langsung tergiur melihat premi murah.
                            Sebaiknya pilih premi asuransi yang sebanding dengan
                            manfaat yang diberikan. Sebab, percuma jika preminya
                            Rp30 ribu per bulan tapi polisnya hanya menanggung
                            biaya rawat inap Rp100 ribu per hari. Tentu saja
                            tidak bisa menutupi biaya rawat inap rumah sakit
                            yang berkisar Rp500 ribuan per hari.
                          </p>
                        </li>
                        <li>
                          <strong>
                            Pilih perusahaan asuransi yang kredibel
                          </strong>
                          <p>
                            Saat ini semakin banyak perusahaan asuransi yang
                            bermunculan di Indonesia. Karenanya, penting untuk
                            setiap nasabah lebih teliti dan jeli dalam memilih
                            <em> brand</em> atau perusahaan asuransi yang
                            kredibel. Cari tahu track record perusahaan, apakah
                            pernah tersandung masalah atau tidak. Selain itu,
                            pastikan perusahaan telah berlisensi Otoritas Jasa
                            Keuangan (OJK) dan terdaftar di AAJI untuk
                            perusahaan asuransi jiwa atau AAUI untuk perusahaan
                            asuransi umum.
                          </p>
                        </li>
                      </ol>
                    </>
                  ),
                },
                {
                  id: '13',
                  title: "Stigma dan kesalahpahaman dalam asuransi",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>Indonesia merupakan salah satu negara yang memiliki tingkat penetrasi asuransi rendah. Ini disebabkan salah satunya karena terdapat banyak stigma dan kesalahpahaman terkait asuransi yang membuat masyarakat menjadi enggan untuk terproteksi asuransi. </p>
                      <p>Berikut ini beberapa kesalahpahaman dalam cara kerja asuransi yang perlu dipahami sebetulnya:</p>
                      <ol>
                        <li>
                          <p>Asuransi rugi karena belum tentu mengajukan klaim</p>
                          <p>Perlu digaris bawahi bahwa risiko sakit dan meninggal dunia bisa terjadi kapan dan kepada siapa saja. Dengan adanya proteksi asuransi, kamu tidak perlu takut ketika harus mengeluarkan biaya besar untuk rawat inap atau rawat jalan. </p>
                          <p>Selain itu, asuransi kesehatan juga bisa digunakan tidak hanya ketika nasabah telah terdiagnosa sakit saja. Sebab, ada beberapa pilihan polis yang menanggung perawatan pencegahan seperti vaksinasi HPV, membersihkan karang gigi, hingga fisioterapi yang umumnya tidak ditanggung oleh BPJS Kesehatan. </p>
                        </li>
                        <li>
                          <p>Premi asuransi kesehatan itu mahal</p>
                          <p>Saat ini ada banyak pilihan polis asuransi kesehatan yang menawarkan berbagai range harga, sehingga bisa disesuaikan dengan anggaran masing-masing calon nasabah. Misalnya saja, di Lifepal Anda bisa menemukan pilihan polis asuransi kesehatan dengan harga mulai dari Rp50 ribu hingga jutaan rupiah per bulan. </p>
                          <p>Selain itu, jika dibandingkan dengan biaya rumah sakit yang terus meningkat dari tahun ke tahun, premi yang dibayarkan terbilang lebih murah dibandingkan biaya jika harus bayar tagihan rumah sakit dengan dana pribadi. </p>
                        </li>
                        <li>
                          <p>Sudah punya BPJS, asuransi kesehatan swasta tidak perlu lagi</p>
                          <p>Memang betul BPJS Kesehatan akan sangat membantu jika Anda mengalami risiko kesehatan. Sebab, BPJS Kesehatan memiliki fungsi yang sama dengan asuransi kesehatan, di mana biaya berobat akan ditanggung. Hanya saja, BPJS Kesehatan memiliki proses administratif yang terbilang cukup rumit. Misalnya saja, ada beberapa kasus dimana nasabah harus mengantre selama berhari-hari untuk kemudian bisa mendapatkan tindakan operasi di rumah sakit. Selain itu, ada juga beberapa jenis obat yang tidak ditanggung oleh BPJS. </p>
                          <p>Sementara dengan asuransi kesehatan swasta, Anda bisa langsung mendapatkan layanan kesehatan di rumah sakit terbaik, baik di Indonesia maupun luar negeri (tergantung polis yang dimiliki). Konklusinya, jika memiliki anggaran yang sangat terbatas, BPJS Kesehatan bisa menjadi solusi yang tepat. Namun, jika memiliki anggaran lebih, sebaiknya lengkapi manfaat BPJS yang dimiliki dengan asuransi kesehatan swasta. </p>
                        </li>
                        <li>
                          <p>Pihak asuransi mempersulit pengajuan klaim </p>
                          <p>Sebagian besar klaim yang ditolak asuransi umumnya disebabkan karena masuk dalam pengecualian asuransi. Perlu dipahami bahwa setiap polis asuransi akan mencantumkan manfaat apa saja yang dapat ditanggung oleh pihak asuransi dan risiko apa saja yang dikecualikan. </p>
                          <p>Misal, nasabah mengajukan klaim untuk perawatan kesehatan akibat penyakit bawaan. Padahal, pre-existing condition (penyakit bawaan) masuk dalam pengecualian polis. Maka klaim akan ditolak. Karena itu, sebelum membeli asuransi, sebaiknya baca terlebih dengan teliti manfaat dan pengecualian polis. </p>
                        </li>
                      </ol>
                    </>
                  ),
                },
                {
                  id: '14',
                  title: "Istilah-Istilah asuransi yang perlu kamu pahami",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>
                        Berikut istilah-istilah asuransi yang sering muncul
                        dalam polis ataupun saat berbicara dengan agen maupun
                        broker asuransi:
                      </p>
                      <ul>
                        <li>
                          Amount Payable dalam asuransi adalah: Sejumlah dana
                          (premi) yang disetujui oleh nasabah pemegang polis
                          asuransi untuk dibayarkan kepada perusahaan asuransi.
                        </li>
                        <li>
                          Agen: Mitra perusahaan asuransi yang bertugas untuk
                          memasarkan produk asuransi atau melayani calon peserta
                          asuransi. Mulai dari menyampaikan ketentuan polis
                          sampai dengan isi perjanjian polis setelah menjadi
                          peserta asuransi.
                        </li>
                        <li>
                          Aktuaria: Profesi dalam asuransi yang menerapkan ilmu
                          matematika, keuangan, dan statistik untuk
                          memperhitungkan risiko dan premi.
                        </li>
                        <li>
                          Ajudikasi: Tahapan penyelesaian sengketa untuk
                          memutuskan apakah klaim yang disampaikan oleh
                          tertanggung dapat diterima atau ditolak oleh
                          perusahaan asuransi (penanggung).
                        </li>
                        <li>
                          Anuitas: Pembayaran yang dilakukan oleh perusahaan
                          asuransi secara berkala selama waktu tertentu.
                        </li>
                        <li>
                          Assignment: Istilah untuk menyebutkan pengalihan dalam
                          asuransi.
                        </li>
                        <li>
                          Assignor: Pihak yang bertugas melakukan assignment hak
                          dan manfaat asuransi dari peserta asuransi
                          (tertanggung) kepada orang lain.
                        </li>
                        <li>
                          Automatic Premium Loan (APL): Ketentuan pengambilan
                          nilai tunai secara otomatis dari polis apabila peserta
                          asuransi tidak membayar premi sampai masa tenggang
                          berakhir.
                        </li>
                        <li>
                          Bancassurance: Produk asuransi yang dipasarkan dan
                          dijual oleh pihak bank.
                        </li>
                        <li>
                          Excess (batas potong): Biaya yang harus dikeluarkan
                          peserta asuransi untuk menutupi kekurangan biaya yang
                          dibayarkan perusahaan asuransi kepada pihak rumah
                          sakit.
                        </li>
                        <li>
                          Biaya Akuisisi: Biaya yang dikeluarkan saat akan
                          dilakukan penerbitan polis.
                        </li>
                        <li>
                          Biaya Top-up: Biaya yang dikeluarkan saat peserta
                          asuransi membayar premi berkala dan premi tunggal.
                        </li>
                        <li>
                          Nilai tunai: Total uang yang diberikan oleh perusahaan
                          asuransi kepada peserta asuransi di rentang waktu
                          tertentu maupun di akhir masa polis
                        </li>
                        <li>
                          Contestable Period: Waktu yang diberikan kepada
                          peserta asuransi untuk membatalkan polis.
                        </li>
                        <li>
                          Cuti Premi: Fitur dalam asuransi yang membolehkan
                          peserta asuransi untuk berhenti membayar premi selama
                          sementara waktu.
                        </li>
                        <li>
                          Dana Investasi: Besarnya dana yang diperoleh dari
                          hasil pembayaran premi dalam jangka waktu tertentu
                          yang sudah dikurangi biaya akuisisi dan beberapa biaya
                          lain.
                        </li>
                        <li>
                          Endowment Plan: Program asuransi dengan dua
                          manfaat/benefit: proteksi dan tabungan.
                        </li>
                        <li>
                          Explanation Of Benefits (EOB): Surat dari pihak
                          perusahaan asuransi sebagai tanda penerimaan klaim
                          yang nantinya akan diberikan kepada peserta asuransi.
                        </li>
                        <li>
                          Field Underwriting: Seleksi awal calon peserta
                          asuransi yang dilakukan perusahaan asuransi.
                        </li>
                        <li>
                          Flat Rate: Biaya premi yang ditentukan perusahaan
                          asuransi yang nominalnya sama untuk setiap periode
                          pembayaran selama masa polis.
                        </li>
                        <li>
                          Free-Look Period: Calon peserta asuransi mendapatkan
                          waktu 14 hari untuk memutuskan bekerja
                          sama/membatalkan polis karena tidak setuju dengan
                          syarat-syarat dan ketentuan yang ada dalam polis.
                        </li>
                        <li>
                          Grace Period: Masa tenggang yang diberikan kepada
                          peserta asuransi selama 30 hari sejak jatuh tempo
                          tanggal pembayaran.
                        </li>
                        <li>
                          Harga Unit: Hasil yang didapatkan dari portofolio
                          investasi yang diperoleh dari hasil nilai aset
                          ditambah keuntungan dari hasil investasi.
                        </li>
                        <li>
                          Ilustrasi Polis: Proyeksi manfaat asuransi yang akan
                          diterima peserta asuransi.
                        </li>
                        <li>
                          Investment-linked Plan: Sering pula disebut sebagai
                          asuransi unit link yang menawarkan dua manfaat:
                          proteksi dan investasi.
                        </li>
                        <li>
                          Jaminan Perlindungan: Jaminan yang diberikan oleh
                          perusahaan asuransi sehingga peserta asuransi bisa
                          membeli produk asuransi tambahan dengan tanpa perlu
                          proses seleksi.
                        </li>
                        <li>
                          Jaminan/Pernyataan Jaminan: Pernyataan yang
                          dikeluarkan calon peserta asuransi mengenai kondisi
                          dari orang atau benda yang diasuransikan.
                        </li>
                        <li>
                          Joint life annuity: Pembayaran premi akan berhenti
                          apabila pihak tertanggung meninggal dunia.
                        </li>
                        <li>
                          Kartu Asuransi: Kartu kepesertaan yang diberikan oleh
                          pihak asuransi kepada tertanggung. Kartu ini dapat
                          digunakan ketika ingin mengajukan klaim.
                        </li>
                        <li>
                          Key Employee (Key Person): Tenaga ahli dari pihak
                          perusahaan asuransi dengan segala kemampuan yang
                          menunjang keberhasilan perusahaan asuransi.
                        </li>
                        <li>
                          Klaim asuransi adalah: Pengajuan penggantian yang
                          diberikan pihak peserta asuransi asuransi kepada
                          perusahaan asuransi. Dengan demikian, peserta asuransi
                          mendapatkan hak dengan semestinya agar pihak asuransi
                          membayarkan kondisi sesuai prosedur yang ada.
                        </li>
                        <li>
                          Klaim Akhir Kontrak: Tuntutan hak peserta asuransi
                          karena kontrak sudah berakhir dengan perusahaan
                          asuransi.
                        </li>
                        <li>
                          Klaim Tertunda: Klaim yang dilakukan peserta asuransi
                          dan belum bisa dibayarkan perusahaan asuransi karena
                          sebab tertentu.
                        </li>
                        <li>
                          Klausul: Pasal-pasal yang terdapat dalam perjanjian
                          polis yang harus dipatuhi peserta asuransi dan
                          perusahaan asuransi.
                        </li>
                        <li>
                          Lapse: Rentang waktu premi yang tidak dibayarkan
                          hingga menyebabkan polis batal (masa efektif polis
                          berhenti).
                        </li>
                        <li>
                          Life Insurance Company: Perusahaan asuransi dengan
                          produk asuransi untuk menerima pelimpahan risiko
                          menyangkut kehidupan (nilai ekonomi dan tertanggung).
                        </li>
                        <li>
                          Loan of Policy: Apabila pihak peserta asuransi meminta
                          pinjaman kepada perusahaan asuransi dalam jumlah
                          tertentu dan disetujui dengan jaminan nilai tunai
                          polis.
                        </li>
                        <li>
                          Late Remittance Offer: Penawaran akhir dari perusahaan
                          asuransi yang digunakan untuk memulihkan polis peserta
                          asuransi yang batal.
                        </li>
                        <li>
                          Law of Large Numbers: Perhitungan dengan konsep hukum
                          bilangan besar berdasarkan data statistik untuk dapat
                          memberikan gambaran persentase segala kemungkinan
                          terjadi pada peserta asuransi.
                        </li>
                        <li>
                          Main Reserve: Cadangan premi yang dimiliki peserta
                          asuransi akan dihitung pada pertengahan tahun.
                        </li>
                        <li>
                          Mail Kit: Brosur penjualan yang digunakan untuk
                          mengirimkan berbagai informasi terkait program
                          asuransi yang dikirim melalui pos kepada calon peserta
                          asuransi agar memudahkan dalam mengambil keputusan
                          untuk ikut program asuransi.
                        </li>
                        <li>
                          Masa Tenggang: Batas akhir yang diberikan perusahaan
                          asuransi kepada pihak peserta asuransi untuk membayar
                          premi yang sudah disepakati.
                        </li>
                        <li>
                          Masa Tunggu: Periode atau masa tertentu yang harus
                          dilewati hingga manfaat atau klaim dapat dicairkan
                          kembali.
                        </li>
                        <li>
                          Minor: Peserta asuransi yang masih berusia di bawah 21
                          tahun.
                        </li>
                        <li>
                          Mortalitas: Waktu perkiraan kematian yang tidak pasti
                          atau juga bisa digunakan untuk menyebut frekuensi
                          kematian.
                        </li>
                        <li>
                          Nilai Aktiva Bersih (NAB): Nilai dasar investasi pada
                          polis asuransi unit link.
                        </li>
                        <li>
                          Nilai Investasi: Nilai total unit link yang terbentuk
                          dalam suatu periode.
                        </li>
                        <li>
                          Occupational Risk/Hazard: Risiko dari pekerjaan
                          peserta asuransi.
                        </li>
                        <li>
                          Payor: Istilah yang digunakan untuk peserta asuransi
                          sebagai pihak yang membayar premi.
                        </li>
                        <li>
                          Pemegang Polis: Orang yang terikat dengan perjanjian
                          yang dibuat dengan perusahaan asuransi dan bertanggung
                          jawab atas segala kewajibannya terhadap perusahaan
                          asuransi.
                        </li>
                        <li>
                          Penanggung: Pihak yang memberikan pertanggungan
                          asuransi, dalam hal ini perusahaan asuransi. Pihak
                          penanggung dalam asuransi syariah (asuransi Islam)
                          memiliki sebutan yang sama dengan asuransi
                          konvensional.
                        </li>
                        <li>
                          Polis asuransi adalah: Surat perjanjian (kontrak
                          asuransi) yang disebut polis. Artinya, perjanjian yang
                          dilakukan pihak peserta asuransi dengan perusahaan
                          asuransi.
                        </li>
                        <li>
                          Premi atau beban polis: Yang dimaksud dengan
                          pengertian premi asuransi adalah nominal pembayaran
                          yang disetujui oleh peserta asuransi dan perusahaan
                          asuransi. Pembayaran premi dapat dilakukan sesuai
                          dengan perjanjian, bulanan, tahunan, atau sesuai
                          kesepakatan.
                        </li>
                        <li>
                          Quarterly Premium: Pembayaran premi setiap tiga bulan
                          sekali.
                        </li>
                        <li>
                          Reasuransi: Usaha yang dilakukan perusahaan asuransi
                          untuk memproteksi dirinya dengan melimpahkan risiko
                          asuransi ke perusahaan asuransi lain.
                        </li>
                        <li>
                          Rider: Manfaat lain yang didapatkan di luar dari
                          manfaat pokok.
                        </li>
                        <li>
                          Risiko: Berbagai kemungkinan buruk yang dapat menimpa
                          seseorang.
                        </li>
                        <li>
                          Tertanggung: Pihak tertanggung dalam asuransi adalah
                          nasabah yang mendapatkan manfaat asuransi, dalam hal
                          ini bisa pemegang polis atau orang lain yang tertera
                          sebagai pihak tertanggung.
                        </li>
                        <li>
                          Uang Pertanggungan: Jumlah uang ganti rugi yang
                          diberikan oleh perusahaan asuransi kalau terjadi klaim
                          dari peserta asuransi atas risiko yang dijamin dalam
                          program asuransi.
                        </li>
                        <li>
                          Underwriter: Seseorang yang memiliki keahlian dalam
                          menilai atau meninjau berbagai resiko peserta asuransi
                          sehingga menentukan calon peserta asuransi berhak
                          menerima asuransi atau tidak.
                        </li>
                      </ul>
                    </>
                  ),
                },
                {
                  id: '15',
                  title: "Seperti apa dasar hukum asuransi di Indonesia?",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>
                        Dasar hukum asuransi di Indonesia diatur melalui Undang Undang No. 2 Thn 1992 dan Undang Undang Hukum Dagang (KUHD).
                      </p>
                      <p>
                        Dijelaskan bahwa pihak penanggung atau perusahaan asuransi mengikatkan diri kepada tertanggung atau pihak nasabah. Nasabah selaku pemegang polis wajib membayar premi asuransi. Dengan menerima premi yang dibayarkan oleh nasabah, penanggung wajib memberikan ganti rugi atas kerusakan atau kehilangan keuntungan yang diderita tertanggung akibat tindakan yang tidak disengaja atau sesuai dengan perjanjian polis.
                      </p>
                      <p>Sementara pengertian asuransi menurut Undang Undang (UU) No. 1 Tahun 1992, dijelaskan bahwa asuransi adalah perjanjian antara dua pihak atau lebih, dimana pihak perusahaan asuransi mengikatkan diri kepada tertanggung (nasabah asuransi), dengan menerima premi asuransi sebagai penggantian kepada tertanggung jika terjadi kerugian.</p>
                      <p>Selain mengikuti UU Tahun 1992, mekanisme asuransi di Indonesia juga diatur oleh Otoritas Jasa Keuangan (OJK). Fungsi OJK sendiri adalah mengatur dan mengawasi kegiatan sektor keuangan perusahaan asuransi. Salah satunya, OJK memastikan bahwa setiap perusahaan asuransi memiliki laporan keuangan yang sehat dari tahun ke tahun.</p>
                    </>
                  ),
                },
                {
                  id: '16',
                  title: "Apa saja prinsip dasar asuransi?",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>
                        Dalam asuransi, terdapat 6 prinsip dasar yang harus dipenuhi. Berikut ini penjelasannya:
                      </p>
                      <ul>
                        <li>
                          <strong>
                            <em>Insurable Interest: </em>
                          </strong>
                          hak untuk mengasuransikan, yang timbul dari suatu hubungan keuangan, antara tertanggung dengan yang diasuransikan dan diakui secara hukum.
                        </li>
                        <li>
                          <strong>
                            <em>Utmost good faith: </em>
                          </strong>
                          tindakan untuk mengungkapkan secara akurat dan lengkap, semua fakta yang material (material fact) mengenai sesuatu yang akan diasuransikan baik diminta maupun tidak. Artinya adalah: si penanggung harus dengan jujur menerangkan dengan jelas segala sesuatu tentang luasnya syarat/kondisi dari asuransi dan si tertanggung juga harus memberikan keterangan yang jelas dan benar atas objek atau kepentingan yang dipertanggungkan.
                        </li>
                        <li>
                          <strong>
                            <em>Proximate cause: </em>
                          </strong>
                          suatu penyebab aktif, efisien yang menimbulkan rantaian kejadian yang menimbulkan suatu akibat tanpa adanya intervensi suatu yang mulai dan secara aktif dari sumber yang baru dan independen.
                        </li>
                        <li>
                          <strong>
                            <em>Indemnity: </em>
                          </strong>
                          mekanisme dimana penanggung menyediakan kompensasi finansial dalam upayanya menempatkan tertanggung dalam posisi keuangan yang ia miliki sesaat sebelum terjadinya kerugian (KUHD pasal 252, 253 dan dipertegas dalam pasal 278).
                        </li>
                        <li>
                          <strong>
                            <em>Subrogation: </em>
                          </strong>
                          pengalihan hak tuntut dari tertanggung kepada penanggung setelah klaim dibayar.
                        </li>
                        <li>
                          <strong>
                            <em>Contribution: </em>
                          </strong>
                          hak penanggung untuk mengajak penanggung lainnya yang sama-sama menanggung, tetapi tidak harus sama kewajibannya terhadap tertanggung untuk ikut memberikan indemnity
                        </li>
                      </ul>
                    </>
                  ),
                },
                {
                  id: '17',
                  title: "Apa saja penunjang usaha asuransi?",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>
                        Untuk memaksimalkan fungsi asuransi di Indonesia, ada
                        pula penunjang usaha asuransi yang berfungsi untuk
                        memastikan perusahaan asuransi berjalan sebagaimana
                        mestinya:
                      </p>
                      <ul>
                        <li>
                          <strong>Perusahaan Pialang Asuransi: </strong>
                          perusahaan yang memberikan jasa perantara dalam
                          menutup asuransi dan memberikan solusi penanganan atas
                          penyelesaian ganti rugi oleh perusahaan asuransi
                          kepada tertanggung.
                        </li>
                        <li>
                          <strong>Perusahaan Pialang Reasuransi: </strong>
                          perusahaan yang memberikan jasa perantara dalam
                          penempatan reasuransi dan solusi penanganan atas
                          penyelesaian ganti rugi oleh perusahaan reasuransi
                          kepada perusahaan asuransi.
                        </li>
                        <li>
                          <strong>
                            Perusahaan Penilai Kerugian Asuransi:{" "}
                          </strong>
                          perusahaan yang memberikan jasa penilaian atas klaim
                          atau konsultasi objek asuransi yang dipertanggungkan.
                        </li>
                        <li>
                          <strong>Bancassurance: </strong>
                          pihak asuransi sebagai lembaga keuangan non bank,
                          bekerjasama dengan perusahaan perbankan untuk
                          menawarkan produk asuransi.
                        </li>
                      </ul>
                    </>
                  ),
                },
                {
                  id: '18',
                  title: "Apa itu Lifepal",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>
                        Lifepal adalah broker asuransi yang berdiri sejak tahun 2018 
                        dengan misi untuk memberikan kemudahan bagi nasabah dalam 
                        membandingkan lebih dari 300 polis asuransi secara online. 
                        Lifepal terus berkembang dan kini telah bermitra dengan 
                        sejumlah perusahaan asuransi ternama di Indonesia
                      </p>
                    </>
                  ),
                },
                {
                  id: '19',
                  title: "Apa keuntungan beli asuransi online di Lifepal?",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>
                        Beli asuransi <em>online</em> dapat memudahkan Anda
                        dalam melindungi diri dan keluarga secara finansial.
                        Sebab, calon nasabah dalam lebih mudah untuk mengukur
                        kebutuhan dan anggaran, dengan cara membandingkan 100+
                        pilihan polis dari perusahaan asuransi yang ada di
                        Indonesia. Cukup gunakan smartphone, nasabah dapat
                        daftar asuransi <em>online</em> kapan saja, bahkan dari
                        rumah.
                      </p>
                      <p>
                        Berikut ini beberapa keuntungan yang bisa diperoleh:
                      </p>
                      <ul>
                        <li>
                          Membandingkan Asuransi: Di Indonesia, setidaknya
                          tersedia pilihan asuransi kesehatan, asuransi jiwa,
                          asuransi kendaraan yang mana masing-masing jenis
                          asuransi tersebut memiliki sub-kategori lagi sesuai
                          kebutuhan nasabah. Misalnya, asuransi mobil terbagi
                          menjadi asuransi{" "}
                          <a
                            title="all risk"
                            href="https://lifepal.co.id/asuransi/all-risk/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            all risk
                          </a>{" "}
                          dan{" "}
                          <a
                            title="asuransi TLO"
                            href="https://lifepal.co.id/asuransi/tlo/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            asuransi TLO
                          </a>{" "}
                          yang mana kedua pilihan ini memiliki manfaat
                          pertanggungan yang berbeda. Nah, Lifepal akan membantu
                          untuk memilihkan jenis asuransi yang tepat sesuai
                          kebutuhan nasabah.
                        </li>
                        <li>
                          Membandingkan Polis: Dengan cara ini, Anda dapat
                          membuat keputusan tepat dan memilih manfaat polis yang
                          benar-benar sesuai kebutuhan. Selain itu, nasabah juga
                          dapat membandingkan manfaat polis, premi, hingga
                          pengecualian klaim dari berbagai perusahaan asuransi
                          sekaligus.
                        </li>
                        <li>
                          Menghemat Waktu: Dengan hadirnya platform{" "}
                          <em>all-in-one </em>
                          asuransi <em>online</em>, Anda dapat membandingkan dan
                          membeli asuransi yang dibutuhkan di mana dan kapan
                          saja. Untuk dokumen pembelian polis pun semuanya dapat
                          dilakukan secara <em>online</em> dalam bentuk PDF
                          format, dan polis akan dikirimkan ke rumah nasabah.
                        </li>
                        <li>
                          Keamanan Dokumen: Tidak jarang nasabah dihadapkan
                          dengan isu kehilangan dokumen polis. Tanpa dokumen
                          polis, tentu saja nasabah akan kesulitan dalam
                          mengajukan klaim. Keadaan ini bisa dihindari jika kita
                          beli polis secara <em>online</em>. Karena biasanya
                          selain dikirimkan buku polis dalam bentuk{" "}
                          <em>hardcopy</em> tapi juga
                          <em> softcopy</em> melalui email.
                        </li>
                        <li>
                          Layanan Nasabah 24x7: Lifepal sebagai{" "}
                          <em>marketplace </em>
                          asuransi <em>online</em> menyediakan layanan nasabah
                          yang dapat diakses 24 jam. Artinya, jika nasabah
                          mengalami risiko kesehatan darurat di malam hari, tim
                          Lifepal dapat membantu nasabahnya dalam pengajuan
                          proses klaim. Layanan konsultasi asuransi ini dapat
                          diakses via telepon di nomor (021) 3111 6121 atau
                          WhatsApp di 0823 3003 0027.
                        </li>
                      </ul>
                    </>
                  ),
                },
                {
                  id: '20',
                  title: "Mengapa membeli asuransi di Lifepal bisa hemat hingga 25%?",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>
                        Lifepal adalah marketplace asuransi yang telah bekerja
                        sama dengan 20+ brand asuransi ternama di Indonesia.
                        Kemudian, Lifepal telah membedah ratusan polis yang ada
                        di Indonesia. Dengan demikian, produk yang ditawarkan
                        pada nasabah merupakan produk terbaik dengan kriteria
                        premi terjangkau tetapi pertanggungan maksimal.
                      </p>
                    </>
                  ),
                },
                {
                  id: '21',
                  title: "Apakah bisa konsultasi asuransi gratis dengan Lifepal untuk mendapatkan produk terbaik?",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>
                        Lifepal menyediakan layanan konsultasi gratis yang
                        ditujukan untuk semua masyarakat Indonesia, guna
                        membantu memahami dan memilih polis yang benar-benar
                        sesuai kebutuhan serta anggaran Anda.
                      </p>
                      <p>
                        Lifepal juga bekerjasama dengan sejumlah ahli asuransi
                        dan <em>financial advisor</em> yang dapat membantu
                        nasabah dalam memilih manfaat produk terbaik dengan
                        premi bersahabat. Untuk mendapatkan konsultasi gratis
                        dapat menghubungi via telepon (021) 3111 6121.
                      </p>
                      <p>
                        Atau mengunjungi kantor kami di alamat CIBIS 9 Lt. 17, jl TB Simatupang No.2 Cilandak Timur, Pasar Minggu Jakarta Selatan 12560.
                      </p>
                    </>
                  ),
                },
                {
                  id: '22',
                  title: "Apakah Lifepal menjual BPJS Kesehatan?",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>
                        Tidak, Lifepal hanya bekerjasama dengan perusahaan asuransi swasta yang telah terdaftar secara resmi dalam Otoritas Jasa Keuangan (OJK).
                      </p>
                      <p>
                        Jika memiliki pertanyaan terkait BPJS Kesehatan dapat menghubungi call center BPJS Kesehatan di nomor 165 yang tersedia 24 jam.
                      </p>
                    </>
                  ),
                },
                {
                  id: '23',
                  title: "Produk asuransi apa saja yang tersedia di Lifepal?",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>
                        Lifepal bekerjasama dengan sejumlah perusahaan asuransi
                        di Indonesia dan menghadirkan lebih dari ratusan pilihan
                        polis yang dapat disesuaikan dengan kebutuhan serta
                        anggaran Anda. Produk asuransi yang tersedia di Lifepal mencakup:
                        <ul>
                          <li>
                            <a
                              title="Asuransi Mobil"
                              href="https://lifepal.co.id/asuransi/mobil/"
                              target="_blank" rel="noreferrer noopener"
                            >
                              Asuransi Mobil
                            </a>
                          </li>
                          <li>
                            <a
                              title="Asuransi Mobil Syariah"
                              href="https://lifepal.co.id/asuransi/mobil-syariah/"
                              target="_blank" rel="noreferrer noopener"
                            >
                              Asuransi Mobil Syariah
                            </a>
                          </li>
                          <li>
                            <a
                              title="Asuransi Kesehatan"
                              href="https://lifepal.co.id/asuransi/kesehatan/"
                              target="_blank" rel="noreferrer noopener"
                            >
                              Asuransi Kesehatan
                            </a>
                          </li>
                          <li>
                            <a
                              title="Asuransi Karyawan"
                              href="https://lifepal.co.id/asuransi/karyawan/"
                              target="_blank" rel="noreferrer noopener"
                            >
                              Asuransi Karyawan
                            </a>
                          </li>
                        </ul>
                      </p>
                    </>
                  ),
                },
                {
                  id: '24',
                  title: "Perusahaan asuransi apa saja yang bekerja sama dengan Lifepal?",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>
                        Lifepal hadir sebagai solusi praktis yang dapat membantu
                        Anda memilih polis asuransi terbaik di Indonesia. Karena
                        itu, Lifepal telah bekerja sama dengan sejumlah
                        perusahaan asuransi ternama di Indonesia, di antaranya:
                      </p>
                      <ul>
                        <li>
                          <a
                            title="Asuransi Mobil ACA"
                            href="https://lifepal.co.id/asuransi/mobil-aca/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Asuransi Mobil ACA
                          </a>
                        </li>                        
                        <li>
                          <a
                            title="Asuransi Mobil KB Insurance"
                            href="https://lifepal.co.id/asuransi/kb-insurance/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Asuransi Mobil KB Insurance
                          </a>
                        </li>
                        <li>
                          <a
                            title="Asuransi Mobil MAG"
                            href="https://lifepal.co.id/asuransi/mobil-mag/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Asuransi Mobil MAG
                          </a>
                        </li>
                        <li>
                          <a
                            title="Asuransi Mobil Mega"
                            href="https://lifepal.co.id/asuransi/mobil-mega-insurance/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Asuransi Mobil Mega
                          </a>
                        </li>                   
                        <li>
                          <a
                            title="Asuransi Mobil Simas Insurtech"
                            href="https://lifepal.co.id/asuransi/simas-insurtech/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Asuransi Mobil Simas Insurtech
                          </a>
                        </li> 
                        <li>
                          <a
                            title="Asuransi Mobil Staco Mandiri"
                            href="https://lifepal.co.id/asuransi/staco-mandiri/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Asuransi Mobil Staco Mandiri
                          </a>
                        </li>
                        <li>
                          <a
                            title="Asuransi Mobil Zurich Syariah"
                            href="https://lifepal.co.id/asuransi/zurich-syariah/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Asuransi Mobil Zurich Syariah
                          </a>
                        </li>                      
                        <li>
                          <a
                            title="Asuransi Kesehatan AXA Insurance"
                            href="https://lifepal.co.id/asuransi/kesehatan-axa/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Asuransi Kesehatan AXA Insurance
                          </a>
                        </li>
                        <li>
                          <a
                            title="Asuransi Kesehatan Lippo General Insurance"
                            href="https://lifepal.co.id/asuransi/kesehatan-lippo/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Asuransi Kesehatan Lippo General Insurance
                          </a>
                        </li>  
                        <li>
                          <a
                            title="Asuransi Kesehatan MAG"
                            href="https://lifepal.co.id/asuransi/kesehatan-mag/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Asuransi Kesehatan MAG
                          </a>
                        </li>             
                        <li>
                          <a
                            title="Asuransi Kesehatan Sun Life"
                            href="https://lifepal.co.id/asuransi/sunlife/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Asuransi Kesehatan Sun Life
                          </a>
                        </li>
                        {" "}
                      </ul>
                    </>
                  ),
                },
                {
                  id: '25',
                  title: "Apakah Lifepal sudah terdaftar di Otoritas Jasa Keuangan (OJK)?",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>
                      Lifepal -- Dikelola oleh PT Anugrah Atma Adiguna, pialang asuransi berizin 
                      dan diawasi oleh OJK sesuai KEP-018/KMK.17/1992 dan anggota APPARINDO 
                      60-2001/APPARINDO/{new Date().getFullYear()}.
                      </p>
                    </>
                  ),
                },
                {
                  id: '26',
                  title: "Bagaimana cara membeli dan daftar asuransi online melalui Lifepal?",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>
                        Berikut ini adalah langkah-langkah transaksi asuransi
                        online melalui situs:
                      </p>
                      <ul>
                        <li>
                          Langkah 1: Kunjungi situs{" "}
                          <a
                            title="lifepal.co.id"
                            href="https://lifepal.co.id/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            lifepal.co.id
                          </a>
                          , pilih kolom jenis asuransi yang diinginkan
                        </li>
                        <li>
                          Langkah 2: Isi formulir data diri, seperti jenis
                          kelamin, usia, nomor telepon, dan email. Kemudian klik
                          <em> Tampilkan Pilihan Polis</em>.
                        </li>
                        <li>
                          Langkah 3: Ikuti langkah-langkah selanjutnya guna
                          melihat polis yang tersedia dan cocok untuk Anda.
                        </li>
                        <li>
                          Langkah 4: Pilih 3 polis yang paling sesuai dengan
                          kebutuhan dan anggaran, kemudian klik tombol
                          <em> Bandingkan</em> pada masing-masing kolom brand.
                        </li>
                        <li>
                          Langkah 5: Klik <em>Bandingkan Produk</em>. Bandingkan
                          dan pelajari manfaat polis.
                        </li>
                        <li>
                          Langkah 6: Jika sudah memutuskan polis yang ingin
                          dibeli, klik <em>Beli</em>.
                        </li>
                        <li>
                          Langkah 7: Registrasi akun dengan mengisi data diri,
                          kemudian klik <em>Check Out</em>.
                        </li>
                      </ul>
                      <p>
                        Berikut ini adalah langkah-langkah transaksi asuransi
                        <em> online</em> via telepon:
                      </p>
                      <ul>
                        <li>
                          Langkah 1: Hubungi call <em>center Lifepal</em> di
                          nomor (021) 3111 6121.
                        </li>
                        <li>
                          Langkah 2: Lakukan konsultasi untuk mengetahui
                          kebutuhan dan anggaran Anda.
                        </li>
                        <li>
                          Langkah 3: Tim Lifepal akan memberikan rekomendasi
                          polis yang sesuai dengan profil nasabah.
                        </li>
                        <li>
                          Langkah 4: Proses pelengkapan dokumen dapat dilakukan
                          via email atau dalam bentuk PDF.
                        </li>
                        <li>
                          Langkah 5: Buku polis dalam bentuk <em>hardcopy</em>{" "}
                          akan dikirimkan ke rumah nasabah.
                        </li>
                      </ul>
                    </>
                  ),
                },
                {
                  id: '27',
                  title: "Apa itu kalkulator Lifepal, keuntungan, dan cara menggunakannya?",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>
                        Lifepal menghadirkan sejumlah kalkulator dan kuis yang
                        dapat membantu menavigasi kebutuhan dan kondisi
                        finansial Anda. Gunakan kalkulator Lifepal untuk
                        mengetahui nilai ideal ketika mengajukan kredit,
                        menghitung inflasi, premi asuransi mobil, dan uang
                        pertanggungan asuransi jiwa yang tepat untuk Anda.
                      </p>
                      <p>Kalkulator Asuransi Jiwa</p>
                      <ul>
                        <li>
                          <a
                            title="Cari Tahu Asuransi Jiwa yang Cocok untuk Saya"
                            href="https://lifepal.co.id/media/kuis-jenis-asuransi-jiwa-terbaik/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Cari Tahu Asuransi Jiwa yang Cocok untuk Saya
                          </a>
                        </li>
                        <li>
                          <a
                            title="Hitung Uang Pertanggungan Asuransi Jiwa yang Ideal"
                            href="https://lifepal.co.id/media/kalkulator-uang-pertanggungan/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Hitung Uang Pertanggungan Asuransi Jiwa yang Ideal
                          </a>
                        </li>
                        <li>
                          <a
                            title="Hitung Kebutuhan Hidup dalam Asuransi Jiwa"
                            href="https://lifepal.co.id/media/kalkulator-nilai-hidup-manusia/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Hitung Kebutuhan Hidup dalam Asuransi Jiwa
                          </a>
                        </li>
                      </ul>
                      <p>Kalkulator Asuransi Mobil</p>
                      <ul>
                        <li>
                          <a
                            title="Cari Tahu Jenis Mobil yang Paling Cocok untuk Saya"
                            href="https://lifepal.co.id/media/kuis-jenis-tipe-mobil-yang-paling-cocok-untuk-kamu/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Cari Tahu Jenis Mobil yang Paling Cocok untuk Saya
                          </a>
                        </li>
                        <li>
                          <a
                            title="Cari Tahu Berapa Premi Asuransi Mobil yang Ideal"
                            href="https://lifepal.co.id/media/kalkulator-premi-asuransi-mobil/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Cari Tahu Berapa Premi Asuransi Mobil yang Ideal
                          </a>
                        </li>
                      </ul>
                      <p>Kalkulator Terkait Kredit</p>
                      <ul>
                        <li>
                          <a
                            title="Hitung Kredit Mobil Bunga Flat"
                            href="https://lifepal.co.id/media/kalkulator-angsuran-kredit-mobil/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Hitung Kredit Mobil Bunga Flat
                          </a>
                        </li>
                        <li>
                          <a
                            title="Hitung Bunga Efektif untuk Kredit Jangka Panjang"
                            href="https://lifepal.co.id/media/kalkulator-angsuran-kredit-efektif/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Hitung Bunga Efektif untuk Kredit Jangka Panjang
                          </a>
                        </li>
                        <li>
                          <a
                            title="Hitung Bunga Flat untuk Angsuran Bulanan Tetap"
                            href="https://lifepal.co.id/media/kalkulator-angsuran-kredit-flat/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Hitung Bunga Flat untuk Angsuran Bulanan Tetap
                          </a>
                        </li>
                      </ul>
                      <p>Kalkulator Tabungan</p>
                      <ul>
                        <li>
                          <a
                            title="Hitung Dana Pensiun untuk Perencanaan Hari Tua"
                            href="https://lifepal.co.id/media/kalkulator-dana-pensiun/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Hitung Dana Pensiun untuk Perencanaan Hari Tua
                          </a>
                        </li>
                        <li>
                          <a
                            title="Hitung Bunga Deposito dan Simulasi Keuntungannya"
                            href="https://lifepal.co.id/media/kalkulator-bunga-deposito/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Hitung Bunga Deposito dan Simulasi Keuntungannya
                          </a>
                        </li>
                        <li>
                          <a
                            title="Hitung Nilai Tabungan Ideal untuk Mencapai Kebebasan
                          Finansial"
                            href="https://lifepal.co.id/media/kalkulator-kebebasan-finansial/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Hitung Nilai Tabungan Ideal untuk Mencapai Kebebasan
                            Finansial
                          </a>
                        </li>
                        <li>
                          <a
                            title="Simulasi Kebutuhan Tabungan Berdasarkan Target Akhir"
                            href="https://lifepal.co.id/media/kalkulator-menabung-bulanan/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Simulasi Kebutuhan Tabungan Berdasarkan Target Akhir
                          </a>
                        </li>
                        <li>
                          <a
                            title="Simulasi Tabungan Berjangka"
                            href="https://lifepal.co.id/media/kalkulator-waktu-menabung/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Simulasi Tabungan Berjangka
                          </a>
                        </li>
                      </ul>
                      <p>Kalkulator Investasi</p>
                      <ul>
                        <li>
                          <a
                            title="Cari Tahu Profil Risiko Investasi – Kenali Profil
                          Risiko Anda"
                            href="https://lifepal.co.id/media/kuis-profil-resiko-investasi/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Cari Tahu Profil Risiko Investasi – Kenali Profil
                            Risiko Anda
                          </a>
                        </li>
                        <li>
                          <a
                            title="Hitung Bunga Bergulung – Simulasi Investasi Masa Depan"
                            href="https://lifepal.co.id/media/kalkulator-bunga-bergulung/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Hitung Bunga Bergulung – Simulasi Investasi Masa
                            Depan
                          </a>
                        </li>
                      </ul>
                      <p>Kalkulator Finansial Lainnya</p>
                      <ul>
                        <li>
                          <a
                            title="Hitung Tarif dan Biaya Listrik"
                            href="https://lifepal.co.id/media/kalkulator-tarif-dan-biaya-listrik/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Hitung Tarif dan Biaya Listrik
                          </a>
                        </li>
                        <li>
                          <a
                            title="Hitung Gaji Bersih"
                            href="https://lifepal.co.id/media/kalkulator-gaji-bersih/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Hitung Gaji Bersih
                          </a>
                        </li>
                        <li>
                          <a
                            title="Hitung Nilai Dana Darurat Idealmu Saat Ini"
                            href="https://lifepal.co.id/media/kalkulator-dana-darurat/"
                            target="_blank" rel="noreferrer noopener"
                          >
                            Hitung Nilai Dana Darurat Idealmu Saat Ini
                          </a>
                        </li>
                      </ul>
                    </>
                  ),
                },
                {
                  id: '28',
                  title: "Dimana saja lokasi bengkel dan asuransi yang bekerja sama dengan Lifepal?",
                  hasSchemaOrFaq: true,
                  body: () => (
                    <>
                      <p>
                        Bengkel rekanan resmi yang bekerja sama dengan Lifepal
                        tersebar cukup luas di Indonesia. Dengan begitu, nasabah
                        dapat mengajukan klaim perbaikan dan perawatan untuk
                        mobil Anda di bengkel terdekat. Lihat daftar bengkel{" "}
                        <a
                          title="rekanan selengkapnya"
                          href="https://lifepal.co.id/bengkel-mobil/"
                          target="_blank" rel="noreferrer noopener"
                        >
                          rekanan selengkapnya
                        </a>
                        !
                      </p>
                    </>
                  ),
                },
              ]}
              hasBorder={true}
              arrowPosition="right"
            />
          </div>
        }

        {
          shouldShowKYCVerification && (
            <KYCVerificationModal
              query={router_.query}
              dispatch={dispatch}
              personId={personId}
              mainProfile={userProfile}
              isOpen
              simpleKYC
            />
          )
        }
      </div>
    </>
  );
};

export const getStaticProps = async () => {

  return {
    props: {}
  };
};

export default LandingPage;
