import React from "react";
import classnames from "classnames";
import getConfig from 'next/config';
import "./StickyWhatsapp.module.scss";

const StickyWhatsapp = () => {
  const { TSO_PHONE_NUMBER, WHATSAPP_NUMBER } = getConfig().publicRuntimeConfig;

  const redirectWhatsapp = () => {
    const text = 'Halo Lifepal, saya memiliki pertanyaan tentang produk asuransi';

    const WhatsAppAPI = `https://api.whatsapp.com/send/?phone=${WHATSAPP_NUMBER}&text=${encodeURIComponent(text)}&app_absent=0`;
    window.location.href = WhatsAppAPI;
  }

  return (
    <div className={classnames('stickyWhatsapp')}>
      <div className="wrap_button">
        <span className="cs-title">Customer Service</span>
        <div
          className={classnames('img_headset', 'gtm_whatsapppopup_buttonclick')}
          title="Call Me Bantuan">
          <a href={`tel:${TSO_PHONE_NUMBER}`}>
            <img alt="call me" loading="lazy" src="/static/images/homepage/headset.svg" width="32px" height="32px"/>
          </a>
        </div>
        <span className='cs-divider'>|</span>
        <div
          className={classnames('img_wa', 'gtm_whatsapppopup_buttonclick')}
          title="Whatsapp Bantuan"
          onClick={() => redirectWhatsapp()}>
          <img alt="whatsapp" loading="lazy" src="/static/images/homepage/whatsapp.svg" width="32px" height="32px"/>
        </div>
      </div>
      <div className='cs-logo'>
        <img src={'/static/images/homepage/head-cs.svg'}/>
      </div>
    </div>
  );
};

export default StickyWhatsapp;
